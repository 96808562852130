<template>
<!-- Main content -->
  <section class="content">
    <div class="btn-box">
        <el-form :inline="true">
            <el-form-item label="选择日期：">
                <el-date-picker
                  v-model="search_time"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="商品ID：">
                <el-input v-model="item_id" placeholder="请输入名称/路由"></el-input>
            </el-form-item>
            <el-form-item label="平台：">
                <el-select v-model="search_status">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="待处理" value="1"></el-option>
                  <el-option label="后台处理" value="2"></el-option>
                  <el-option label="脚本自动处理" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="search(1)">搜索</el-button></el-button> 
            </el-form-item>
        </el-form>
    </div>
    <el-tabs v-model="active" @tab-click="handleClick">
      <el-tab-pane label="全部" name=""></el-tab-pane>
      <el-tab-pane label="待处理" name="1"></el-tab-pane>
      <el-tab-pane label="已处理" name="2"></el-tab-pane>
      <el-tab-pane label="自动处理" name="3"></el-tab-pane>
    </el-tabs>
    <el-table
      v-loading="loading"
      :data="report_list"
      border>
      <el-table-column label="id" prop="id"></el-table-column>
      <el-table-column label="商品ID" prop="item_id"></el-table-column>
      <el-table-column label="好价标题" prop="item_title"></el-table-column>
      <el-table-column label="最近举报" prop="">
        <template slot-scope="item">
          {{item.row.last_time | dateTime}}
        </template>
</el-table-column>
<el-table-column label="举报次数" prop="report_times"></el-table-column>
<el-table-column label="状态">
    <template slot-scope="item">
          {{item.row.status | status}}
        </template>
</el-table-column>
<el-table-column label="操作" fixed="right">
    <template slot-scope="item">
          <el-button type="primary" size="mini" v-if="item.row.status==1" @click="getReportDate(item.row.id)">处理举报</el-button></el-button>
          </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page"></el-pagination>
<!-- 编辑 -->
<el-dialog title="举报处理" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <div class="title">
            基础信息
        </div>
        <el-row>
            <el-col :span="4">ID：{{response.id}}</el-col>
            <el-col :span="20">商品ID：{{response.item_id}}</el-col>
            <el-col :span="24">标题：{{response.item_title}}</el-col>
            <el-col :span="24">特色：{{response.special}}</el-col>
            <el-col :span="4">原价：{{response.price}}</el-col>
            <el-col :span="6">券后价：{{response.end_price}}</el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <img :src="response.item_pic">
            </el-col>
        </el-row>
        <div class="title">
            优惠信息
        </div>
        <div class="coupon" v-for="item in response.coupon_info">
            <span>{{item.coupon_name}}</span>
            <div>
                <el-button size="mini" @click="updateCouponStatus(item.id)">标记失效</el-button>
                <el-button type="" size="mini" @click="check(item.url)">查看</el-button>
            </div>
        </div>
        <div class="title">
            举报记录
        </div>
        <div>
            <el-table :data="response.report_record" border>
                <el-table-column label="举报时间">
                    <template slot-scope="report">{{report.row.create_time | dateTime}}</template>
                </el-table-column>
                <el-table-column label="举报理由">
                    <template slot-scope="report">{{report.row.report_type | selling}}</template>
                </el-table-column>
            </el-table>
        </div>
        <div class="title">
            详情描述
        </div>
        <div v-html="response.description"></div>
    </el-form>

    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">已处理下一个</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(window.localStorage.getItem('common'))
    import * as api from '@/config/api'
    export default {
        name: 'goodPriceReport',
        components: {},
        data() {
            return {
                loading: true,
                dialog: false,
                is_alter: false, //是否修改

                page: 1,
                pagesize: 10,
                total: 0,
                report_list: [],

                id: '',

                item_id: '',
                search_time: [],
                search_status: '',

                active: '', //tab选择

                response: {}, // 详情

            }
        },
        watch: {},
        filters: {
            status(val) {
                switch (Number(val)) {
                    case 1:
                        return '待处理'
                        break;
                    case 2:
                        return '后台已处理'
                        break;
                    case 3:
                        return '自动处理'
                        break;
                    default:
                        return ''
                        break;
                }
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            },
            selling(val) {
                let name
                if (JSON.stringify(common.selling_report_list).indexOf(val) != -1) {
                    common.selling_report_list.map((value, index) => {
                        if (val === value.type) {
                            name = value.name
                        }
                    })
                }
                return name
            }
        },
        mounted() {
            this.getSellingReportList()
        },
        methods: {
            // 获取列表
            getSellingReportList(page, size, status) {
                console.log(this.search_time)
                api.getSellingReportList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                    item_id: this.item_id,
                    status: status ? status : this.search_status,
                    start_time: this.search_time != null ? this.search_time[0] : '',
                    end_time: this.search_time != null ? this.search_time[1] : '',
                }, res => {
                    status ? this.active = status : ''
                    this.total = Number(res.data.count)
                    this.report_list = res.data.list
                    this.loading = false
                })
            },
            // 页面切换
            sizeChange(val) {
                this.pagesize = val
                this.getSellingReportList()
            },
            currentChange(val) {
                this.page = val
                this.getSellingReportList()
            },
            // 搜索
            search(Number) {
                this.page = Number
                this.getSellingReportList(Number)
            },
            // tab切换
            handleClick(tab) {
                this.page = 1
                this.pagesize = 10
                this.search_status = tab.name
                this.getSellingReportList(this.page, this.pagesize, tab.name)
            },
            // 添加或修改详情
            alter() {
                api.updateReportInfo({
                    id: this.id
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    if (res.data.next_id == "0") {
                        this.dialog = false
                    } else {
                        this.getReportDate(res.data.next_id)
                    }
                    this.getSellingReportList()
                })
            },
            // 获取详情
            getReportDate(id) {
                this.id = id
                api.getSellingReportDate({
                    id: id,
                }, res => {
                    console.log(res)
                    this.response = res.data
                    this.dialog = true
                })
            },
            // 标记优惠券失效
            updateCouponStatus(id) {
                api.updateCouponStatus({
                    id: id,
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                })
            },
            // 查看优惠券
            check(url) {
                window.open(url)
            }
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .coupon {
        margin-bottom: 10px;
        span {
            margin-right: 10px;
        }
        .w_100 {
            width: 100px;
            margin-right: 10px;
        }
        .el-button {
            text-align: center;
        }
    }
    
    [class*=el-col-] {
        text-align: left;
    }
    
    .title {
        margin-bottom: 20px;
        text-align: left;
        font-weight: bold;
    }
    
    img {
        width: 100%;
    }
    
    .coupon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            margin-right: 0;
        }
    }
</style>